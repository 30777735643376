import React, { useState } from "react";
import '../../scss/login.scss';
import roririImage from "../../img/roriri.jpg";
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaArrowLeft } from 'react-icons/fa';
import { Button } from 'react-bootstrap';

function AuthForm() {
  const [isFlipped, setIsFlipped] = useState(false);
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://treasurebackend.roririsoft.com/access/login/', {
        username: username,
        password: password
      });
      
      if (response.status === 200) {
        navigate('/MD-Roriri-admindashboard');
      }
    } catch (error) {
      setError(
        <span style={{ color: 'red', fontWeight: 'bold' }}>
          Login failed. Please check your credentials.
        </span>
      );
    }
  };

  return (
    <div className="login">
      <div className="back-button-container">
        <Button className="back-btn" onClick={() => navigate('/')} style={{ background: "orange" }}>
          <FaArrowLeft /> {/* Back Icon */}
        </Button>
      </div>
      <div className="container">
        <input type="checkbox" id="flip" checked={isFlipped} onChange={handleFlip} />
        <div className="cover">
          <div className="front">
            <img src={roririImage} alt="Front Cover" />
            <div className="text">
              <span className="text-1" style={{ fontFamily: "Poppins" }}>Treasure Hunt <br /> Login</span>
              <span className="text-2" style={{ fontFamily: "Poppins" }}>Let's get connected</span>
            </div>
          </div>
          <div className="back">
            <img className="backImg" src={roririImage} alt="Back Cover" />
            <div className="text">
              <span className="text-1">Complete miles of journey <br /> with one step</span>
              <span className="text-2">Let's get started</span>
            </div>
          </div>
        </div>
        <div className="forms">
          <div className="formContent">
            {!isFlipped ? (
              <div className="loginForm">
                <div className="title">Login</div>
                <form onSubmit={handleLogin}>
                  <div className="inputBoxes">
                    <div className="inputBox">
                      <i className="fas fa-user"></i>
                      <input
                        type="text"
                        placeholder="Enter your username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                      />
                    </div>
                    <div className="inputBox">
                      <i className="fas fa-lock"></i>
                      <input
                        type="password"
                        placeholder="Enter your password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                    </div>
                    {error && <div className="error">{error}</div>}
                    <div className="button inputBox">
                      <input type="submit" value="Submit" />
                    </div>
                  </div>
                </form>
              </div>
            ) : (
              <div className="signupForm">
                <div className="title">Signup</div>
                <form>
                  <div className="inputBoxes">
                    <div className="inputBox">
                      <i className="fas fa-user"></i>
                      <input type="text" placeholder="Enter your name" required />
                    </div>
                    <div className="inputBox">
                      <i className="fas fa-envelope"></i>
                      <input type="text" placeholder="Enter your email" required />
                    </div>
                    <div className="inputBox">
                      <i className="fas fa-lock"></i>
                      <input type="password" placeholder="Enter your password" required />
                    </div>
                    <div className="button inputBox">
                      <input type="submit" value="Submit" />
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthForm;