import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button, Table, Navbar, Container, Row, Col, Form, Pagination, Modal } from 'react-bootstrap';
import AddEventPopup from '../Add Components/AddEvent';
import '../../scss/TeamDetailsPage.scss';
import { FaArrowLeft} from 'react-icons/fa';
import s1 from '../../img/k.png';
import s2 from '../../img/n.png';
import s3 from '../../img/p.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEye } from '@fortawesome/free-solid-svg-icons';
import 'react-datepicker/dist/react-datepicker.css';
import ExcelComponent from '../Add Components/ExcelComponent';
import { FaFileDownload } from "react-icons/fa";



// Sample teams data
const teams = [
  { name: "Kurinji", members: ["Mydeen", "Manish", "Muthurasi", "Managala Durga", "Sneha"], imgSrc: s1 },
  { name: "Marutham", members: ["Antony", "Vashwa", "Rajkumar", "Petchimuthu", "Jones Sherlin"], imgSrc: s2 },
  { name: "Neithal", members: ["Kanthasamy", "Asha", "Mariraj", "Durga", "Nathiya"], imgSrc: s3 },
  { name: "Palai", members: ["Sriram", "Mugeshwaran", "Chandru", "Uma Maheswari", "YesuBalan"] },
  { name: "Mullai", members: ["Vasanth", "Siva Suresh", "Jebarathi", "Sajtha", "Arumugam", "Srinivas"] }
];

// Function to get team details
const getTeamDetails = (teamName) => {
  return teams.find((team) => team.name === teamName);
};


const AdminPage = () => {
  const { teamName } = useParams();
  const navigate = useNavigate();
  const team = getTeamDetails(teamName);

  const [teamEvents, setTeamEvents] = useState([]);
  const [totalEventCount, setTotalEventCount] = useState(0);
  const [showAddEventPopup, setShowAddEventPopup] = useState(false);
  const [showMembers] = useState(false);
  const [editingEventId, setEditingEventId] = useState(null);
  const [editableEventData, setEditableEventData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const eventsPerPage = 5;
  const [eventCounts, setEventCounts] = useState({});
  const [editingPointsId, setEditingPointsId] = useState(null); // State to track points editing
  const [pointsData, setPointsData] = useState({}); // State for editing points value
  const [category, setCategory] = useState('');  // Category state
  const [status, setStatus] = useState('');  // Status state
  const [startDate, setStartDate] = useState('');
  const [excelModal, setExcelModal] = useState(false);

  useEffect(() => {
    fetchEvents();
  }, [teamName, category, status, startDate]);

 
  const fetchEvents = () => {
    
    // Convert category and status to lowercase for consistency
    const apiUrl = `http://treasurebackend.roririsoft.com/cms/list/${teamName}/?category=${category}&status=${status}&date=${startDate}`;
    // console.log("Fetching events with URL:", apiUrl);  // Log the API URL for debugging
  
    axios.get(apiUrl)
      .then(response => {
        // console.log("API Response:", response);  // Log the response for debugging
        const events = response.data.data.results || [];
        const totalCount = response.data.data.count || 0;
        setTeamEvents(events);
        setTotalEventCount(totalCount);
      })
      .catch(error => {
        // console.error("Error fetching events:", error);
        setTeamEvents([]);
        setTotalEventCount(0);
      });
  };
  
  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  useEffect(() => {
    if (teamName) {
      // Fetch event counts
      axios.post('https://treasurebackend.roririsoft.com/cms/total-count/', { team: teamName })
        .then(response => {
          setEventCounts(response.data.data);
        })
        .catch(error => {
         // console.error("Error fetching event counts:", error);
        });

      // Fetch events (removing the extra API call for event details)
      axios.get(`https://treasurebackend.roririsoft.com/cms/list/${teamName}/?page=${currentPage}`)
        .then(response => {
          const events = response.data.data.results || [];
          const totalCount = response.data.data.count || 0;

          // Directly set the fetched events without additional API calls
          setTeamEvents(events);
          setTotalEventCount(totalCount);
        })
        .catch(error => {
        //  console.error("Error fetching events:", error.response || error.message);
          setTeamEvents([]);
          setTotalEventCount(0);
        });
    }
  }, [currentPage, teamName]);




  const handleShowAddEventPopup = () => setShowAddEventPopup(true);
  const handleCloseAddEventPopup = () => setShowAddEventPopup(false);

  const addEvent = (newEvent) => {
    const updatedEvents = [...teamEvents, { id: teamEvents.length + 1, ...newEvent }];
    setTeamEvents(updatedEvents);
    setTotalEventCount(prevCount => prevCount + 1);
    setShowAddEventPopup(false);
  };

  const startEditEvent = (event) => {
    setEditingEventId(event.id);
    setEditableEventData({ ...event });
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setEditableEventData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleEditPointsClick = (eventId, points) => {
    setEditingPointsId(eventId); // Set the event being edited for points
    setPointsData({ points }); // Set the current points value
  };

  const handlePointsChange = (e) => {
    setPointsData({ points: e.target.value });
  };
  const saveEvent = () => {
    axios.patch(`https://treasurebackend.roririsoft.com/cms/new_form/${editingEventId}/`, editableEventData)
      .then(response => {
        // Update the specific event in the teamEvents state
        setTeamEvents(prevEvents =>
          prevEvents.map(event =>
            event.id === editingEventId ? { ...event, ...editableEventData } : event
          )
        );
        setEditingEventId(null);
        setEditableEventData({});
      })
      .catch(error => {
        // console.error("There was an error updating the event!", error.response?.data || error.message);
      });
  };


  // Separate function to fetch team events
  const fetchTeamEvents = () => {
    axios.get(`https://treasurebackend.roririsoft.com/cms/list/${teamName}/?page=${currentPage}`)
      .then(response => {
        const events = response.data.data.results || [];
        const totalCount = response.data.data.count || 0;
        setTeamEvents(events);  // Set the updated events data
        setTotalEventCount(totalCount);  // Set the total count
      })
      .catch(error => {
        // console.error("Error fetching events:", error);
      });
  };

  // Use the function in useEffect to refetch events on page load and refresh
  useEffect(() => {
    if (teamName) {
      fetchTeamEvents();  // Fetch team events on page load
    }
  }, [teamName, currentPage]);  // Ensure it fetches when teamName or currentPage changes



  const cancelEdit = () => {
    setEditingEventId(null);
    setEditableEventData({});
  };
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState({});
  // Handle View More popup
  const handleViewMore = (uuid) => {
    axios.get(`https://treasurebackend.roririsoft.com/cms/details/${uuid}/`)
      .then(response => {
        setPopupContent(response.data);
        setShowPopup(true);
      })
      .catch(error => {
        // console.error("Error fetching event details", error);
      });
  };

  const handleClosePopup = () => setShowPopup(false);


  const totalPages = Math.ceil(totalEventCount / eventsPerPage);



  // Pagination with only 2 pages visible at a time
  const visiblePages = () => {
    const pages = [];
    let start = Math.max(currentPage - 1, 1);
    let end = Math.min(start + 1, totalPages);

    if (totalPages > 1 && start === 1 && totalPages > 2) {
      end = Math.min(start + 1, totalPages);
    } else if (totalPages > 2 && start > 1) {
      start = Math.max(end - 2, 1);
    }

    for (let i = start; i <= end; i++) {
      pages.push(i);
    }

    return pages;
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  const renderPopupContent = () => {
    const { 
      category, team, date, status, 
      college_name, phone_number, full_name, 
      email, duration, description, mode 
    } = popupContent;
  
    // Helper function to handle null or empty values
    const displayValue = (value, placeholder = 'N/A') => value || placeholder;
  
    if (category === 'Industrial Visit') {
      return (
        <>
          <p><strong>Team:</strong> {displayValue(team)}</p>
          <p><strong>Date:</strong> {displayValue(date)}</p>
          <p><strong>Status:</strong> {displayValue(status)}</p>
          <p><strong>Category:</strong> Industrial Visit</p>
          <p><strong>College Name:</strong> {displayValue(college_name)}</p>
          <p><strong>Phone Number:</strong> {displayValue(phone_number)}</p>
          <p><strong>Description:</strong> {displayValue(description)}</p>
        </>
      );
    } else if (category === 'Course') {
      return (
        <>
          <p><strong>Team:</strong> {displayValue(team)}</p>
          <p><strong>Date:</strong> {displayValue(date)}</p>
          <p><strong>Status:</strong> {displayValue(status)}</p>
          <p><strong>Category:</strong> {displayValue(category)}</p>
          <p><strong>Mode:</strong> {displayValue(mode)}</p>
          <p><strong>Duration:</strong> {displayValue(duration)}</p>
          <p><strong>Full Name:</strong> {displayValue(full_name)}</p>
          <p><strong>Phone Number:</strong> {displayValue(phone_number)}</p>
          <p><strong>Description:</strong> {displayValue(description)}</p>
        </>
      );
    } else if (category === 'Internship') {
      return (
        <>
          <p><strong>Team:</strong> {displayValue(team)}</p>
          <p><strong>Date:</strong> {displayValue(date)}</p>
          <p><strong>Status:</strong> {displayValue(status)}</p>
          <p><strong>Category:</strong> {displayValue(category)}</p>
          <p><strong>Email:</strong> {displayValue(email)}</p>
          <p><strong>Duration:</strong> {displayValue(duration)}</p>
          <p><strong>Full Name:</strong> {displayValue(full_name)}</p>
          <p><strong>Phone Number:</strong> {displayValue(phone_number)}</p>
          <p><strong>Description:</strong> {displayValue(description)}</p>
        </>
      );
    } else if (category === 'Social Media') {
      return (
        <>
          <p><strong>Team:</strong> {displayValue(team)}</p>
          <p><strong>Date:</strong> {displayValue(date)}</p>
          <p><strong>Status:</strong> {displayValue(status)}</p>
          <p><strong>Description:</strong> {displayValue(description)}</p>
        </>
      );
    } else {
      return <p>No details available.</p>;
    }
  };
 

  
  return (
    <div className='team-details-container'>
      <ExcelComponent
        excelModal={excelModal}
        setExcelModal={setExcelModal}
      />
      <Navbar className="navbar-custom">
        <div className='back'>
          <Button className="back-btn" onClick={() => navigate('/MD-Roriri-admindashboard')} style={{ background: "orange" }}>
            <FaArrowLeft /> {/* Back Icon */}
          </Button>
        </div>
        <Navbar.Brand href="#" style={{ color: 'white', font: 'poppins', justifyContent: 'center' }}>
          Admin {teamName} Dashboard
        </Navbar.Brand>
        <div style={{ marginLeft: "auto", marginRight: "20px" }}>
          {/* <Button className="sidebar-btn" onClick={handleShowAddEventPopup}>
            🏆Add Event
          </Button> */}

          <Button style={{  marginRight: "20px", backgroundColor:"orange" }} onClick={() => setExcelModal((prev) => !prev)}><FaFileDownload /> Excel</Button>
        </div>
      </Navbar>

      <Container fluid>
        <Row>


          <Col xs={12} md={12} className="main-content">
            <div className="content-container">
              <div className="stats-section">
                <div className="stats-item">
                  <h6>Total Count</h6>
                  <p>{totalEventCount}</p>
                </div>
                <div className="stats-item">
                  <h6>IV</h6>
                  <p>{eventCounts.industrial_visit || 0}</p>
                </div>
                <div className="stats-item">
                  <h6>2 Month Course</h6>
                  <p>{eventCounts.course_student_count?.course_2month || 0}</p>
                </div>
                <div className="stats-item">
                  <h6>4 Month Course</h6>
                  <p>{eventCounts.course_student_count?.course_4month || 0}</p>
                </div>
                <div className="stats-item">
                  <h6>6 Month Course</h6>
                  <p>{eventCounts.course_student_count?.course_6month || 0}</p>
                </div>

                <div className="stats-item">
                  <h6>1 Week Internship</h6>
                  <p>{eventCounts.intern_student_count?.Internship_1week || 0}</p>
                </div>
                <div className="stats-item">
                  <h6>2 Week Internship</h6>
                  <p>{eventCounts.intern_student_count?.Internship_2week || 0}</p>
                </div>
                <div className="stats-item">
                  <h6>1 Month Internship</h6>
                  <p>{eventCounts.intern_student_count?.Internship_1month || 0}</p>
                </div>
                <div className="stats-item">
                  <h6>3 Month Internship</h6>
                  <p>{eventCounts.intern_student_count?.Internship_3month || 0}</p>
                </div>
                <div className="stats-item">
                  <h6>1 Year Course</h6>
                  <p>{eventCounts.course_student_count?.course_1year || 0}</p>
                </div>
                <div className="stats-item">
                  <h6>2 Year Course</h6>
                  <p>{eventCounts.course_student_count?.course_2year || 0}</p>
                </div>

              </div>
              <div className="filter-section" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px', fontSize: '12px', width: 'fit-content' }}>
              <Form inline style={{ display: 'flex', gap: '10px' }}>
                <Form.Group style={{ marginRight: '10px' }}>
                  <Form.Label>Category</Form.Label>
                  <Form.Control as="select" value={category} onChange={handleCategoryChange}>
                    <option value="">All</option>
                    <option value="Industrial Visit">Industrial Visit</option>
                    <option value="Course">Course</option>
                    <option value="Internship">Internship</option>
                    <option value="Social Media">Social Media</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group style={{ marginRight: '10px' }}>
                  <Form.Label>Status</Form.Label>
                  <Form.Control as="select" value={status} onChange={handleStatusChange}>
                    <option value="">All</option>
                    <option value="Enquired">Enquired</option>
                    <option value="Intrested">Intrested</option>
                    <option value="Pending">Pending</option>
                    <option value="Enquired">Enquired</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group style={{ marginRight: '10px' }}>
      <Form.Label>Date</Form.Label>
      <Form.Control type="date" value={startDate} onChange={handleStartDateChange} />
    </Form.Group>
              </Form>
</div>
              
              <div className="table-container">

                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Category</th>
                      <th>Status</th>
                      <th>Follow Up</th>
                      <th>Description</th>
                      <th>Points</th> {/* Added Points column */}
                      <th>Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {teamEvents.length > 0 ? (
                      teamEvents.map((event) => (
                        <tr key={event.id}>
                          <td>{formatDate(event.date)}</td>
                          <td>{event.category}</td>

                          <td>
                            {editingEventId === event.id ? (
                              <Form.Control
                                as="select"
                                name="status"
                                value={editableEventData.status}
                                onChange={handleFieldChange}
                              >
                                <option>Enquired</option>
                                <option>Intrested</option>
                                <option>Pending</option>
                                <option>Not Intrested</option>
                              </Form.Control>
                            ) : (
                              event.status
                            )}
                          </td>

                          {/* Keep follow_up as view-only (no editing option) */}
                          <td>
                            <span style={{ color: event.follow_up ? 'inherit' : '#999', fontStyle: 'italic' }}>
                              {event.follow_up ? formatDate(event.follow_up) : 'Add follow-up date'}
                            </span>
                          </td>

                          {/* Keep description as view-only (no editing option) */}
                          <td>
                            <span style={{ color: event.description ? 'inherit' : '#999', fontStyle: 'italic' }}>
                              {event.description || 'Add description'}
                            </span>
                          </td>

                          <td>
                            {editingEventId === event.id ? (
                              <Form.Control
                                type="number"
                                name="points"
                                value={editableEventData.points}
                                onChange={handleFieldChange}
                              />
                            ) : (
                              event.points
                            )}
                          </td>

                          <td>
                            {editingEventId === event.id ? (
                              <>
                                <Button variant="success" onClick={saveEvent}>Save</Button>
                                <Button variant="secondary" onClick={cancelEdit}>Cancel</Button>
                              </>
                            ) : (
                              <>
                                <div className="icon-container">
                                  <FontAwesomeIcon
                                    icon={faEdit}
                                    className="icon edit-icon"
                                    onClick={() => startEditEvent(event)}
                                    title="Edit"
                                  />
                                  <div className="icon-container">
                                    <FontAwesomeIcon
                                      icon={faEye}
                                      className="icon view-icon"
                                      onClick={() => handleViewMore(event.uuid)}
                                      title="View More"
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6">No events available</td>
                      </tr>
                    )}
                  </tbody>

                </Table>
              </div>
              <br></br>

              <Pagination>
                <Pagination.Prev onClick={handlePreviousPage} disabled={currentPage === 1} />
                {visiblePages().map(pageNumber => (
                  <Pagination.Item key={pageNumber} active={pageNumber === currentPage} onClick={() => handlePageChange(pageNumber)}>
                    {pageNumber}
                  </Pagination.Item>
                ))}
                <Pagination.Next onClick={handleNextPage} disabled={currentPage === totalPages} />
              </Pagination>
              {showMembers && (
                <div className="members-list">
                  {team.members && team.members.map((member, index) => (
                    <div key={index} className="member">
                      <img src={team.imgSrc} alt={member} className="member-img" />
                      <p>{member}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      {/* Popup Modal */}
      <Modal show={showPopup} onHide={handleClosePopup}>
        <Modal.Header closeButton>
          <Modal.Title>Event Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {renderPopupContent()}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosePopup}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <AddEventPopup
        show={showAddEventPopup}
        handleClose={handleCloseAddEventPopup}
        addEvent={addEvent}
        defaultTeam={teamName} // Pass teamName as defaultTeam
      />

    </div>
  );
};

export default AdminPage;