import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './Components/Team Components/Dashboard';
import TeamDetailsPage from './Components/Team Components/TeamDetailsPage';
import AuthForm from './Components/Admin Components/login';
import AdminDashboard from './Components/Admin Components/Admidashboard';
import AdminPage from './Components/Admin Components/Admin';


const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Dashboard/>} />
        <Route path="/team/:teamName" element={<TeamDetailsPage />} />
        <Route path="/login" element={<AuthForm/>}/>
        {/* <Route path='/admin' element={<AdminPage/>}/> */}
        <Route path='/MD-Roriri-admindashboard' element={<AdminDashboard/>}/>
        <Route path="/adminteam/:teamName" element={<AdminPage/> }/>
      </Routes>
    </Router>
  );
};

export default App;