import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Card from './Card';
import pic1 from "../../img/1.jpg";
import pic2 from "../../img/2.jpg";
import pic3 from "../../img/3.png";
import pic4 from "../../img/4.jpg";
import pic5 from "../../img/5.jpg";
import "../../scss/Dashboard.scss";
import ImageSlider from '../Add Components/ImageSlider';
import { FaSignInAlt } from 'react-icons/fa';

// Team data with images
const teams = [
  { name: "Kurinji", imgSrc: pic1, code: "KU-Kurinji" },
  { name: "Mullai", imgSrc: pic4, code: "MU-Mullai" },
  { name: "Marutham", imgSrc: pic2, code: "MA-Marudham" },
  { name: "Neithal", imgSrc: pic3, code: "NE-Neithal" },
  { name: "Palai", imgSrc: pic5, code: "PA-Palai" },
];

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [scores, setScores] = useState({});
  const navigate = useNavigate();

  // Navigate to login page
  const handleCardClick = () => {
    navigate(`/login`);
  };

  // Fetch team scores from the API
  useEffect(() => {
    const fetchScores = async () => {
      try {
        const response = await fetch('https://treasurebackend.roririsoft.com/cms/total-points/');
        const data = await response.json();

        // Check if the response is successful
        if (data.status === 'success') {
          setScores(data.data);  // Update scores state with team scores
        }
      } catch (error) {
        // console.error("Error fetching team scores:", error);
      }
    };

    fetchScores();

    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="dashboard">
      {/* Loader */}
      {loading && (
        <div className="page-loader">
          <div className="loader">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
        </div>
      )}

      {/* Navigation Bar */}
      <nav className="navbar">
        <h1  style={{ textAlign: 'center', width: '100%' }}  className="navbar-title">Team Scoreboard</h1>
        <button  className="login-button" onClick={handleCardClick}>
          <FaSignInAlt className="login-icon" /> Login
        </button>
      </nav>

      {/* Team Cards */}
      <div className="cards-container">
        {teams.map((team) => (
          <Card
            key={team.name}
            teamName={team.name}
            score={scores[team.name] || "0"}  // Get score from API data or default to 0
            imgSrc={team.imgSrc}
            code={team.code}
          />
        ))}
      </div>
      <ImageSlider />
    </div>
  );
};

export default Dashboard;
