import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Card as BootstrapCard } from 'react-bootstrap';
import '../../scss/Card.scss';

const Card = ({ teamName, score, imgSrc, code }) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(`/team/${teamName}`);
  };

  return (
    <BootstrapCard className="custom-card" onClick={handleCardClick}>
      <BootstrapCard.Img variant="top" src={imgSrc} alt={`${teamName} logo`} />
      <BootstrapCard.Body>
        <BootstrapCard.Title className="custom-card-title team-name">
          {teamName}
        </BootstrapCard.Title>
        <BootstrapCard.Text className="custom-card-text team-code">
          {code} {/* Display the team code here */}
        </BootstrapCard.Text>
        <BootstrapCard.Text className="custom-card-text score">
          Score: {score}
        </BootstrapCard.Text>
      </BootstrapCard.Body>
    </BootstrapCard>
  );
};

Card.propTypes = {
  teamName: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
  imgSrc: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
};

export default Card;
