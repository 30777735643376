import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import "../../scss/AddEventPopup.scss";

// Define options as constants
const TEAMS = [
  { value: 'Kurinji', label: 'Kurinji' },
  { value: 'Mullai', label: 'Mullai' },
  { value: 'Neithal', label: 'Neithal' },
  { value: 'Palai', label: 'Palai' },
  { value: 'Marutham', label: 'Marutham' },
];

const STATUS = [
  { value: 'Intrested', label: 'Intrested' },
  { value: 'Enrolled', label: 'Enrolled' },
  { value: 'Pending', label: 'Pending' },
  { value: 'Not Intrested', label: 'Not Intrested' },
];
const INTERNSHIP_DURATIONS = [
  { value: '1 Week', label: '1 Week' },
  { value: '2 Week', label: '2 Week' },
  { value: '1 Month', label: '1 Month' },
  { value: '3 Month', label: '3 Month' },
];


const CATEGORIES = [
  { value: 'Industrial Visit', label: 'Industrial Visit' },
  { value: 'Intership', label: 'Internship' },
  { value: 'Course', label: 'Course' },
  { value: 'Social Media', label: 'Social Media' },
];

const DURATION = [
  { value: '2 Month', label: '2 Month' },
  { value: '4 Month', label: '4 Month' },
  { value: '6 Month', label: '6 Month' },
  { value: '1 Year', label: '1 Year' },
  { value: '2 Year', label: '2 Year' },
];

const MODE = [
  { value: 'Online', label: 'Online' },
  { value: 'Offline', label: 'Offline' },
];

const AddEventPopup = ({ show, handleClose, defaultTeam }) => {
  // Get today's date in YYYY-MM-DD format
  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const todayDate = getTodayDate();

  const initialFormData = {
    team: defaultTeam ,
    date: getTodayDate(),
    follow_up: '',
    status: '',
    category: '',
    full_name: '',
    college_name: '',
    course: '',
    duration: '',
    phone_number: '',
    email: '',
    mode: '',
    teacher_count: 0,
    student_count: 0,
    description: '',
    points: 0,
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submitFormData(formData);
  };

  const submitFormData = async (data) => {
    try {
      // Log the data being sent

      const response = await fetch('https://treasurebackend.roririsoft.com/cms/new_form/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      // console.log(data)
      if (!response.ok) {
        const errorText = await response.text();
        // console.error('Server Error:', errorText); // Log server error message
        throw new Error('Network response was not ok.');
      }

      const result = await response.json();
      // console.log(result); // Log the result to see the server's response
      alert('Form submitted successfully!');
      handleClose(); // Close the modal on successful submission
      setFormData(initialFormData);
      // Reset the form after submission
      window.location.reload();
    } catch (error) {
      // console.error('Error:', error);
      alert('An error occurred while submitting the form.');
    }
  };

  const renderAdditionalFields = () => {
    switch (formData.category) {
      case 'Industrial Visit':
        return (
          <>
            <Row>
              <Col md={6}>
                <Form.Group controlId="formCollegeName">
                  <Form.Label>College Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="college_name"
                    value={formData.college_name}
                    onChange={handleChange}
                    
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formTeacherCount">
                  <Form.Label>Teacher Count</Form.Label>
                  <Form.Control
                    type="number"
                    name="teacher_count"
                    value={formData.teacher_count}
                    onChange={handleChange}
                    
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId="formStudentCount">
                  <Form.Label>Student Count</Form.Label>
                  <Form.Control
                    type="number"
                    name="student_count"
                    value={formData.student_count}
                    onChange={handleChange}
                    
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formPhoneNumber">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="phone_number"
                    value={formData.phone_number}
                    onChange={handleChange}
                    
                  />
                </Form.Group>
              </Col>
            </Row>
          </>
        );
      case 'Intership':  // Keep the spelling consistent as 'Internship' if needed.
        return (
          <>
            <Row>
              <Col md={6}>
                <Form.Group controlId="formFullName">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="full_name"
                    value={formData.full_name}
                    onChange={handleChange}
                    
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group controlId="formInternshipDuration">
                  <Form.Label>Internship Duration</Form.Label>
                  <Form.Control
                    as="select"
                    name="duration"
                    value={formData.duration}
                    onChange={handleChange}
                    
                  >
                    <option value="">Select Internship Duration</option>
                    {INTERNSHIP_DURATIONS.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formPhoneNumber">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="phone_number"
                    value={formData.phone_number}
                    onChange={handleChange}
                    
                  />
                </Form.Group>
              </Col>
            </Row>
          </>
        );


      case 'Course':
        return (
          <>
            <Row>
              <Col md={6}>
                <Form.Group controlId="formMode">
                  <Form.Label>Mode</Form.Label>
                  <Form.Control
                    as="select"
                    name="mode"
                    value={formData.mode}
                    onChange={handleChange}
                    
                  >
                    <option value="">Select Mode</option>
                    {MODE.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formDuration">
                  <Form.Label>Duration</Form.Label>
                  <Form.Control
                    as="select"
                    name="duration"
                    value={formData.duration}
                    onChange={handleChange}
                    
                  >
                    <option value="">Select Duration</option>
                    {DURATION.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId="formStudentName">
                  <Form.Label>Student Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="full_name"
                    value={formData.full_name}
                    onChange={handleChange}
                    
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formPhoneNumber">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="phone_number"
                    value={formData.phone_number}
                    onChange={handleChange}
                    
                  />
                </Form.Group>
              </Col>
            </Row>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Modal show={show} onHide={() => {
      setFormData(initialFormData); // Reset form data on modal close
      handleClose(); // Call the original handleClose function
    }}>
      <Modal.Header closeButton>
        <Modal.Title>Add Event</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formDate">
                <Form.Label>Date</Form.Label>
                <Form.Control
                  type="date"
                  name="date"
                  placeholder='Date'
                  value={formData.date}
                  onChange={handleChange}
                  max={getTodayDate()}
                  
                />
              </Form.Group>
            </Col>

            <Col md={6}>
            <Form.Group controlId="formTeam">
    <Form.Label>Team</Form.Label>
    <Form.Control
      type="text"
      name="team"
      value={formData.team || defaultTeam} // Set default value
      readOnly // Make it non-editable
    />
  </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formStatus">
                <Form.Label>Status</Form.Label>
                <Form.Control
                  as="select"
                  name="status"
                  placeholder='Status'
                  value={formData.status}
                  onChange={handleChange}
                  
                >
                  <option value="">Select Status</option>
                  {STATUS.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formCategory">
                <Form.Label>Category</Form.Label>
                <Form.Control
                  as="select"
                  name="category"
                  placeholder='category'
                  value={formData.category}
                  onChange={handleChange}
                  
                >
                  <option value="">Select Category</option>
                  {CATEGORIES.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formDate">
                <Form.Label>Follow_up</Form.Label>
                <Form.Control
                  type="date"
                  name="follow_up"
                  placeholder='follow_up'
                  value={formData.follow_up}
                  onChange={handleChange}
                  min={getTodayDate()}
                />
              </Form.Group>
            </Col>
          </Row>
          {/* <Col md={6}>
                <Form.Group controlId="formTeacherCount">
                  <Form.Label>Points</Form.Label>
                  <Form.Control
                    type="number"
                    name="points"
                    value={formData.points}
                    onChange={handleChange}
                   
                  />
                </Form.Group>
              </Col> */}

          {renderAdditionalFields()}
          <Form.Group controlId="formDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              name="description"
              placeholder='Add Description'
              value={formData.description}
              onChange={handleChange}
              rows={3}
            // required
            />

          </Form.Group>
          <br></br>

          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddEventPopup;