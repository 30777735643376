import React from 'react';
import Slider from 'react-slick';
import '../../scss/ImageSlider.scss'; // Optional: Create a separate SCSS file for custom styles
import s1 from '../../img/k.png';
import s2 from '../../img/n.png';
import s3 from '../../img/p.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const ImageSlider = () => {
    const images = [
        s1,
        s2,
        s3
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true, // Enable autoplay
        autoplaySpeed: 2000, // Delay between slides in milliseconds (1 second)
    };

    return (
        <div className="image-slider">
            <Slider {...settings}>
                {images.map((img, index) => (
                    <div key={index}>
                        <img src={img} alt={`Slide ${index}`} />
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default ImageSlider;
