import React, { useState, useEffect } from "react"; 
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import axios from 'axios';

export default function ExcelComponent({ excelModal, setExcelModal }) {
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [team, setTeam] = useState("all"); // Default to 'all' for all teams
    const [category, setCategory] = useState("all"); // Default to 'all' for all categories

    // Function to calculate the previous September 1st
    const getPreviousSeptemberFirst = () => {
        const today = new Date();
        const currentYear = today.getFullYear();
        const septemberFirstThisYear = new Date(currentYear, 8, 1); // September is month 8 (0-indexed)

        // If today is before September 1st, use the previous year's September 1st
        if (today < septemberFirstThisYear) {
            return new Date(currentYear - 1, 8, 1);
        } else {
            return septemberFirstThisYear;
        }
    };

    // Set default start and end dates when the component is first rendered
    useEffect(() => {
        const previousSeptember = getPreviousSeptemberFirst();
        const today = new Date();

        // Format the date to 'YYYY-MM-DD' for the input value
        const formattedPreviousSeptember = previousSeptember.toISOString().split("T")[0];
        const formattedToday = today.toISOString().split("T")[0];

        setStartDate(formattedPreviousSeptember);
        setEndDate(formattedToday);
    }, []);

    const onDownloadClick = async () => {
        if (startDate && endDate) {
            try {
                // Construct the URL based on selected team and category
                const apiUrl = `https://treasurebackend.roririsoft.com/cms/generate-report/${team}/${category}/${startDate}/${endDate}/`;

                // console.log("API URL:", apiUrl);  // Log the API URL to ensure it's correct

                const response = await axios.get(apiUrl, {
                    responseType: 'blob' // Set response type to blob for Excel download
                });

                // Create a downloadable Excel file link
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'report.xlsx'); // Specify file name
                document.body.appendChild(link);
                link.click();

                // Cleanup
                window.URL.revokeObjectURL(url);
            } catch (error) {
                // console.error("Error downloading the Excel file", error);
            }
        } else {
            alert("Please select both start and end dates");
        }
    };

    return (
        <Modal show={excelModal} onHide={() => setExcelModal(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title>Date Filter</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col xs={12} sm={6}>
                            <Form.Group controlId="formStartDate">
                                <Form.Label>Start Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    placeholder="Start Date"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} sm={6}>
                            <Form.Group controlId="formEndDate">
                                <Form.Label>End Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    placeholder="End Date"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={6}>
                            <Form.Group controlId="formTeam">
                                <Form.Label>Team</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={team}
                                    onChange={(e) => setTeam(e.target.value)}
                                >
                                    <option value="all">All</option>
                                    <option value="Kurinji">Kurinji</option>
                                    <option value="Marutham">Marutham</option>
                                    <option value="Neithal">Neithal</option>
                                    <option value="Palai">Palai</option>
                                    <option value="Mullai">Mullai</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col xs={12} sm={6}>
                            <Form.Group controlId="formCategory">
                                <Form.Label>Category</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={category}
                                    onChange={(e) => setCategory(e.target.value)}
                                >
                                    <option value="all">All</option>
                                    <option value="Industrial Visit">Industrial Visit</option>
                                    <option value="Course">Course</option>
                                    <option value="Internship">Internship</option>
                                    <option value="Social Media">Social Media</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setExcelModal(false)}>
                    Close
                </Button>
                <Button variant="primary" onClick={onDownloadClick}>
                    Download Excel
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
